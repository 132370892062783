import React, { useState } from 'react';
import axios from 'axios';
import './Model.css' // Import your CSS

const FileUploadModal = ({ closeModal }) => {
  const [id, setId] = useState('');
  const [jsonFile, setJsonFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('id', id);
    formData.append('json_file', jsonFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/google_analytics/own_analytics`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Include Bearer token

        },
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }

    // Close the modal after submission
    closeModal();
    window.location.reload();
  };

  return (
    <div className="modal-overlay">
     <div style={{backgroundColor:'white',borderRadious:'10px'}} >
     <div className="modal-content">
        <h2>Upload ID and JSON File</h2>

        {/* Form inside the modal */}
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div>
            <label htmlFor="id">GA_PROPERTY_ID:</label>
            <input
              type="text"
              id="id"
              value={id}
              onChange={(e) => setId(e.target.value)}
              placeholder="Enter GA_PROPERTY_ID"
              required
            />
          </div>

          <div>
            <label htmlFor="json_file">Upload JSON File:</label>
            <input
              type="file"
              id="json_file"
              onChange={(e) => setJsonFile(e.target.files[0])}
              accept=".json"
              required
            />
          </div>

          <div className="modal-actions">
            <button1  type="button" onClick={handleSubmit}>Submit</button1>
            <button1 type="button" onClick={closeModal}>
              Close
            </button1>
          </div>
        </form>
      </div>
     </div>

    </div>
  );
};

export default FileUploadModal;
