import React, { useState } from "react";
import axios from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import "./AdminLogin.scss";
// import { IoMdEyeOff } from "react-icons/io";
// import { IoEye } from "react-icons/io5";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Add this at the top with other imports

import { IoMailUnread } from "react-icons/io5";
import { RiLockPasswordFill } from "react-icons/ri";
import { signInWithEmail, handleSignInError } from "../UserAuthentication/Config";
import { CloseFullscreen } from "@mui/icons-material";

const AdminLogin = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false,
  });
  const [error, setError] = useState("");
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setTouchedFields({
      ...touchedFields,
      [name]: true,
    });
  };

  const handleSignIn = async () => {
    const { email, password } = formData;
  
    setError("");
    setServerError("");
    setLoading(true);
  
    if (!email || !password) {
      setTouchedFields({
        email: !email,
        password: !password,
      });
      setError("Please fill in all the required fields.");
      setLoading(false);
      return;
    }
  
    try {
      // Convert email to lowercase
      const emailLowerCase = email.toLowerCase();
      console.log('YO EMAIL TO LOWER CASE',emailLowerCase)
      // Authenticate the user using signInWithEmail
      const signInStatus = await signInWithEmail(emailLowerCase, password);
      const idToken = await signInStatus.user.getIdToken();
  
      // Send the idToken to the backend for verification
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/user_login`,
        { idToken, email: emailLowerCase }, // Use lowercase email here
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        // Store JWT token and user details
        localStorage.setItem("jwt", response.data.token);
        sessionStorage.setItem("admin_name", response.data.admin_name);
        console.log("Admin logged in successfully");
        // Navigate to admin dashboard
        window.location.href = "/dashboard";
      } else {
        setServerError("Failed to log in. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      const errorMessage = handleSignInError(error.message);
      setServerError(errorMessage || "An unexpected error occurred.");
    }
  
    setLoading(false);
  };
  

  return (
    <div className="admin-login">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSignIn();
        }}
      >
        <div>
          <h1>Login to your Account</h1>
          <p style={{ marginBottom: "0px" }}>
            Please enter your information to access your account
          </p>
        </div>
        <div className="input-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder={
              touchedFields.email && !formData.email
                ? "Email is required"
                : "Enter Your Email"
            }
            value={formData.email}
            onChange={handleChange}
            className={touchedFields.email && !formData.email ? "error" : ""}
          />
          <IoMailUnread
            style={{
              position: "absolute",
              top: "36px",
              left: "12px",
              color: "gray",
            }}
          />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder={
              touchedFields.password && !formData.password
                ? "Password is required"
                : "Enter Your Password"
            }
            value={formData.password}
            onChange={handleChange}
            className={
              touchedFields.password && !formData.password ? "error" : ""
            }
          />
          <RiLockPasswordFill
            style={{
              position: "absolute",
              top: "36px",
              left: "12px",
              color: "gray",
            }}
          />
          <div
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              color:'gray'
            }}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </div>
        </div>
        {error && <div className="error-message" style={{width:"100%", border:"red solid 1px", padding:"10px", borderRadius:'10px', color:"red", display:'flex' , justifyContent:"space-between", alignItems:"center"}}>{error} <AiFillCloseCircle size={20}/></div>}
        {serverError && <div className="error-message">{serverError}</div>}
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <button className="submit-btn" type="submit" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminLogin;
