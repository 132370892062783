




import './Forms.css'; // Import CSS file for additional styling
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import useFetchData from "./useFetchData";

function LinkedInTopicIdeas() {
  const [formData, setFormData] = useState({
    topic: '',
    audience: '',
    tone: ''
  });
  const [apiResponse, setApiResponse] = useState(null); // State to store API response
  const [error, setError] = useState(null); // State to store error message
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [saving, setSaving] = useState(false); // State to manage saving status
  const [user, setUser] = useState(null);
  const { data, loadingHook, errorHook } = useFetchData(`${process.env.REACT_APP_API_URL}/user_crud/retrieve_voices`);


  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Form validation
    if (!formData.topic || !formData.audience || !formData.tone) {
      setError('All fields are required.');
      return;
    }
  
    setLoading(true);
    // Clear previous response
    setApiResponse(null);
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/charli/charli/LinkedIn`, {
        params: {
            topic: formData.topic,
            audience: formData.audience,
            tone: formData.tone,
            email: user?.email // Retrieve email from user state
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
        }
    });
    
      setApiResponse(response.data);
      setError(null);
    } catch (error) {
      console.error('Error:', error);
      setError(error.response.data.response);
      setApiResponse(null);
    } finally {
      setLoading(false);
    }
  };
  
 

   const handleSave = async () => {
    if (!apiResponse) return;

    setSaving(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/charli/save`, {
        topic: formData.topic,
        audience: formData.audience,
        tone: formData.tone,
        generatedResponse: apiResponse.response,
        email: user?.email,
        cardType: 'LinkedIn Topic Ideas'
    }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
        }
    });
    

      if (response.status === 200) {
        alert('Response saved successfully!');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while saving the response.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="form-container">
      <div className="left-panel">
        <form style={{ maxWidth: '400px', margin: 'auto' }} onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <span className="form-title">LinkedIn Topic Ideas</span>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="topic">Topic:</label>
            <input
              id="topic"
              type="text"
              placeholder="Marketing"
              style={{ width: '100%', padding: '9px' }}
              onChange={handleChange}
            />
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="audience">Audience:</label>
            <textarea
              id="audience"
              placeholder="Marketers, companies, business owners"
              style={{ width: '100%', minHeight: '100px', padding: '5px' }}
              onChange={handleChange}
            ></textarea>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="toneOfVoice" >Tone of Voice:</label>
            <select 
              id="toneOfVoice"
             className="dropdown-style"
              onChange={handleChange}
              value={formData.toneOfVoice}
            >
              <option value="">Select a tone...</option>
              {!loadingHook && data?.voices?.map((voice) => (
                <option key={voice.id} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>
            {loadingHook && <p>Loading voices...</p>}
            {errorHook && <p>Error loading voices: {errorHook.message}</p>}
          </div>
          <button type="submit" id='submit-btn' disabled={loading}>
            {loading ? 'Generating...' : 'Generate'}
          </button>
        </form>
      </div>

      <div className="right-panel">
        {/* Response Window */}
        <div className="response-window">
          {/* Display API response or error message */}
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {apiResponse && (
            <div>
              <ComponentWithApiResponse data={apiResponse['response']} />
              <button onClick={handleSave} id='submit-btn' disabled={saving}>
                {saving ? 'Saving...' : 'Save Response'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Component that receives API response as prop
function ComponentWithApiResponse({ data }) {
  // Use the data in the component
  return (
    <div>
      {/* Display the data */}
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
}

export default LinkedInTopicIdeas;

