import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Bizz.scss";

const Bizz = () => {
  const [BusinessName, setFullBusinessName] = useState("");
  const [BusinessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [BusinessAddress, setBusinessAddress] = useState("");
  const [industry, setIndustry] = useState("");
  const [WebsiteURL, setWebsiteURL] = useState("");
  const [submittedData, setSubmittedData] = useState(null);
  const [user, setUser] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: BusinessName,
      address: BusinessAddress,
      website: WebsiteURL,
      industry: industry,
      contact_number: BusinessPhoneNumber,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/business/add_business`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.status === 201) {
        setSubmittedData(formData);
        setShowSuccess(true);
        localStorage.setItem("businessInfo", JSON.stringify(formData));
        setTimeout(() => setShowSuccess(false), 2000);
      } else {
        console.error("Failed to add business");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{height:'100vh'}}>
    <p style={{fontSize:'25px',fontWeight:'600',marginTop:'28px',marginLeft:'20px',width:'100%'}}>Business Info</p>
    <div className="bizz-container">
      
      <div className="bizz-form" onSubmit={handleSubmit}>
        <h1 className="bizz-form-group-two" style={{ fontSize: '25px',width:'100%', textAlign: 'center',width:'100%' }}>Basic Details</h1>

        <div className="bizz-form-group-one">
          <label htmlFor="businessName" className="bizz-form-group-label">
            Business Name
          </label>
          <input
            className="bizz-form-group-input"
            type="text"
            id="businessName"
            value={BusinessName}
            onChange={(e) => setFullBusinessName(e.target.value)}
          />
        </div>

        <div className="bizz-form-group-one">
          <label htmlFor="businessAddress" className="bizz-form-group-label">
            Business Address
          </label>
          <input
            className="bizz-form-group-input"
            type="text"
            id="businessAddress"
            value={BusinessAddress}
            onChange={(e) => setBusinessAddress(e.target.value)}
          />
        </div>

        <div className="bizz-form-group-one">
          <label htmlFor="businessPhone" className="bizz-form-group-label">
            Phone Number
          </label>
          <input
            className="bizz-form-group-input"
            type="text"
            id="businessPhone"
            value={BusinessPhoneNumber}
            onChange={(e) => setBusinessPhoneNumber(e.target.value)}
          />
        </div>

        <div className="bizz-form-group-one">
          <label htmlFor="industry" className="bizz-form-group-label">
            Industry
          </label>
          <input
            className="bizz-form-group-input"
            type="text"
            id="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          />
        </div>

        <div className="bizz-form-group-two">
          <label htmlFor="websiteURL" className="bizz-form-group-label">
            Website URL
          </label>
          <input
            className="bizz-form-group-input"
            type="text"
            id="websiteURL"
            value={WebsiteURL}
            onChange={(e) => setWebsiteURL(e.target.value)}
          />
        </div>

        <div className="bizz-form-button-group">
          <button type="button" onClick={handleSubmit} style={{fontWeight:'500'}}>Submit</button>
        </div>

        {showSuccess && (
          <p className="success-message">Business added successfully!</p>
        )}
      </div>
    </div>
    </div>
  );
};

export default Bizz;
